@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .link-btn {
    @apply p-2 underline inline-block border-blue-600 text-blue-600 border rounded-lg dark:text-blue-400 dark:border-blue-400;
  }
  
  .btn {
    @apply p-2 bg-blue-800 text-white shadow inline-block rounded dark:bg-blue-800;
  }
  
  .input {
    @apply border mb-2 p-2 shadow-inner dark:bg-gray-800 dark:border-gray-700 dark:text-white
  }
}

[data-re-earthbar-panel-portal] {
  position: absolute;
  z-index: 99999999;
  width: 100%;
}
